<!-- 投资机会 -->
<template>
  <div class="change-frame">
    <chance-detail
      :detailShow.sync="detailShow"
      :nowData="nowData"
    ></chance-detail>
    <el-dialog
      class="mapDialog"
      width="1000px"
      title="产业地图"
      :visible.sync="showMapShow"
    >
      <div id="wrap"></div>
      <span slot="footer">
        <el-button @click="showMapShow = false">关 闭</el-button>
      </span>
    </el-dialog>
    <div class="body">
      <top showTitle="投资机会"></top>
      <div class="content">
        <div class="left">
          <div class="search_box">
            <el-input
              class="ipt"
              v-model="queryParams.projectName"
              suffix-icon="el-icon-search"
              placeholder="请输入项目名称关键词"
              @keydown.native.enter="startSearch"
            ></el-input>
            <el-button round size="normal" @click="startSearch"
              >筛 选</el-button
            >
          </div>
          <div class="type_box">
            <div
              :class="{
                type_item: true,
                active: activeType == gardenObj.code,
              }"
            >
              <div class="show" @click="changeType(1, gardenObj, 'garden')">
                <div class="label">{{ gardenObj.name }}</div>
                <i class="el-icon-arrow-right"></i>
              </div>

              <div
                class="sub_list"
                v-show="
                  gardenObj.gardenAreaList && activeType == gardenObj.code
                "
              >
                <div
                  :class="{
                    sub_item: true,
                    sub_active: nowGarden.code == garden.code,
                  }"
                  v-for="garden in gardenObj.gardenAreaList"
                  :key="garden.id"
                  @click="changeType(2, garden, 'garden')"
                >
                  <div class="sub_title">
                    <div class="text">{{ garden.gardenName }}</div>
                    <i class="el-icon-caret-right"></i>
                  </div>
                </div>
              </div>
            </div>
            <div
              :class="{ type_item: true, active: activeType == item.code }"
              v-for="item in subjectData"
              :key="item.code"
              @click="changeType(1, item)"
            >
              <div class="show">
                <div class="label">{{ item.name }}</div>
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <!-- <div class="eaches">
              <div
                :class="{ each: true, each_active: activeSubType == item.code }"
                v-for="item in subSubject"
                :key="item.code"
                @click="changeType(2, item)"
              >
                {{ item.name }}<i class="el-icon-caret-right"></i>
              </div>
            </div> -->
          </div>
        </div>
        <div class="right" v-show="!mapShow">
          <div class="title">
            {{ nowSubject.name }}<i class="el-icon-caret-right"></i>
          </div>
          <el-table
            ref="table"
            v-loading="loading"
            :data="tableData"
            :height="innerHeight"
            stripe
            highlight-current-row
            @cell-click="chooseNow"
          >
            <el-table-column
              label="项目名称"
              prop="projectName"
            ></el-table-column>
            <el-table-column
              label="所属产业"
              width="200"
              prop="industryName"
            ></el-table-column>
            <el-table-column
              label="所属地区"
              width="300"
              prop="gardenName"
            ></el-table-column>
            <el-table-column label="产业地图">
              <template slot-scope="{ row }">
                <div class="look" @click.stop="lookMap(row)">查看地图</div>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            background
            small
            @current-change="pageIdxChange"
            :current-page.sync="queryParams.pageIndex"
            layout="prev, pager, next"
            :total="totalData"
            :page-size="queryParams.pageSize"
          >
          </el-pagination>
        </div>
        <div class="right map" v-show="mapShow">
          <div id="container"></div>
          <div class="desc">
            <div class="choose">
              <div class="choice active">园区详情</div>
            </div>
            <div class="bottom" v-html="handleDesc"></div>
          </div>
        </div>
      </div>
      <bottom></bottom>
    </div>
  </div>
</template>

<script>
import Bottom from "../../components/bottom.vue";
import ChanceDetail from "../../components/chanceDetail.vue";
import top from "../../components/top.vue";

import AMapLoader from "@amap/amap-jsapi-loader";
export default {
  components: { top, Bottom, ChanceDetail },
  data() {
    return {
      innerHeight: window.innerHeight - 490,
      gardenObj: {}, // 所有园区字典对象
      nowGarden: {}, // 当前园区字典对象
      subjectData: [],
      subSubject: [], // 次级数据
      activeType: "", // 第一级激活类
      nowSubject: {}, // 当前选中的二级字典对象
      tableData: [],
      totalData: 0,
      dicQueryParams: {
        flag: 0,
        investFlag: 0,
      },
      queryParams: {
        pageIndex: 1,
        pageSize: 10,
        typeCode: "",
        projectName: "",
      },
      loading: false,
      nowData: {},
      detailShow: false,
      map: null,
      showMap: null,
      AMap: null,
      infoWindow: null,
      mapShow: false, // 判断当前展示地图还是展示投资机会
      showMapShow: false,
      allPathes: [], // 展示地图坐标集
    };
  },
  created() {
    const innerWidth = window.innerWidth;
    if (innerWidth < 1500 && innerWidth > 1400) {
      this.innerHeight = window.innerHeight - 450;
    } else if (innerWidth < 1400) {
      this.innerHeight = window.innerHeight - 410;
    } else {
      this.innerHeight = window.innerHeight - 490;
    }
    this.getGarden();
    let { name } = this.$route.query;
    if (name) {
      this.queryParams.projectName = name;
      this.startSearch();
    }
    this.getList();
  },
  computed: {
    handleDesc() {
      let desc = "";
      if (this.nowGarden.gardenDesc) {
        desc = this.nowGarden.gardenDesc.replace(/\n/g, "<br>");
      }
      return desc;
    },
  },
  mounted() {},
  methods: {
    async getGarden() {
      let res = await this.$api.getIndustryDic({
        industryFlag: 1,
        parkFlag: 1,
      });
      this.gardenObj = res.data[0];
      if (!this.$route.query.name) {
        // 若参数带有name则先展示投资机会，否则先展示园区
        this.mapShow = true;
        this.activeType = this.gardenObj.code;
        this.nowGarden = this.gardenObj.gardenAreaList[0];
        this.initMap();
      }
    },
    async getList() {
      let res = await this.$api.getInvestDic(this.dicQueryParams);
      this.subjectData = res.data;
      this.subSubject = [];
    },
    changeType(level, item, type) {
      this.$refs.table.setCurrentRow();
      this.queryParams.projectName = "";
      this.mapShow = type == "garden";
      //产业园区的操作
      if (type == "garden") {
        if (level == 1 && item.gardenAreaList.length > 0) {
          this.activeType = item.code;
          // 默认先渲染第一个园区
          this.nowGarden = item.gardenAreaList[0];
        } else if (level == 2) {
          this.nowGarden = item;
        }
        this.map ? this.initCover() : this.initMap();

        this.queryParams.typeCode = "";
        this.subSubject = [];
        return;
      }
      this.nowGarden = {};
      // 查询投资机会
      if (level == 1) {
        this.activeType = item.code;
        this.subSubject = item.children || [];
        this.nowSubject = item;
        this.queryParams.pageIndex = 1;
        this.queryParams.typeCode = this.activeType;
        this.searchTableData();
      }
    },
    initMap() {
      AMapLoader.load({
        key: this.$mapKey,
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.Scale"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.AMap = AMap;
          this.map = new AMap.Map("container", {
            //设置地图容器id
            zoom: 14, //初始化地图级别
            center: [102.987592, 32.069268], //初始化地图中心点位置
          });
          this.map.addControl(new AMap.Scale());

          this.infoWindow = new AMap.InfoWindow({
            autoMove: true,
            offset: new AMap.Pixel(0, -30),
          });
          this.initCover();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    initCover() {
      this.map.clearMap();
      // 园区二级字典被激活后才渲染覆盖物
      if (this.nowGarden.code) {
        let circle = new this.AMap.Circle({
          center: [this.nowGarden.longitude, this.nowGarden.latitude],
          radius: this.nowGarden.radius, //半径
          borderWeight: 3,
          strokeColor: "#FF33FF",
          strokeWeight: 6,
          strokeOpacity: 0.2,
          fillOpacity: 0.4,
          strokeStyle: "dashed",
          strokeDasharray: [10, 10],
          // 线样式还支持 'dashed'
          fillColor: "#1791fc",
          zIndex: 50,
          cursor: "pointer",
        });
        // 自定义信息窗体
        circle.content = `<span style='white-space: nowrap;'>${this.nowGarden.gardenName}</span>`;
        circle.on("mouseover", this.openInfo);
        circle.on("mouseout", this.closeInfo);
        circle.setMap(this.map);
        this.map.setFitView();
      }
    },
    // 地图信息窗口展开
    openInfo(e) {
      this.infoWindow.setContent(e.target.content);
      this.infoWindow.open(this.map, e.target._opts.center);
    },
    // 地图信息窗口关闭
    closeInfo() {
      this.infoWindow.close();
    },
    async searchTableData() {
      let res = await this.$api.getInvestList(this.queryParams);
      this.tableData = res.data.list;
      this.totalData = res.data.total;
    },
    async startSearch() {
      if (!this.queryParams.projectName) {
        return;
      }
      this.loading = true;
      let res = await this.$api.getInvestList(this.queryParams);
      this.loading = false;
      this.activeType = "";
      this.nowGarden = {};
      this.nowSubject.name = "搜索结果";
      this.tableData = res.data.list;
      this.totalData = res.data.total;
      this.mapShow = false;
    },
    pageIdxChange(val) {
      this.queryParams.pageIndex = val;
      this.searchTableData();
    },
    chooseNow(val) {
      if (val) {
        this.nowData = val || {};
        this.detailShow = true;
      }
    },
    async lookMap(item) {
      let res = await this.$api.getIndustryById({ id: item.industryId });
      if (!res.data) {
        this.allPathes = [];
        this.$notify.warning({
          title: "提示",
          message: "当前投资机会暂无相关产业",
        });
        return;
      }
      this.showMapShow = true;
      const pathJson = res.data.longituAndLatitu; // json 坐标集
      this.allPathes = pathJson ? JSON.parse(pathJson) : [];
      setTimeout(() => {
        this.showMap ? this.initWrapCover() : this.initWrapMap();
      }, 500);
    },
    initWrapMap() {
      AMapLoader.load({
        key: this.$mapKey,
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.Scale"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.AMap = AMap;
          this.showMap = new AMap.Map("wrap", {
            //设置地图容器id
            zoom: 14, //初始化地图级别
            center: [102.987592, 32.069268], //初始化地图中心点位置
          });
          this.showMap.addControl(new AMap.Scale());

          this.initWrapCover();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    initWrapCover() {
      this.showMap.clearMap();
      this.allPathes.forEach((ele) => {
        let marker = new this.AMap.CircleMarker({
          center: ele,
          radius: 10, //3D视图下，CircleMarker半径不要超过64px
          strokeColor: "white",
          strokeWeight: 2,
          strokeOpacity: 0.5,
          fillColor: "#f82f20",
          fillOpacity: 0.5,
          zIndex: 10,
          bubble: true,
          cursor: "pointer",
          clickable: true,
        });
        marker.setMap(this.showMap);
      });
      this.showMap.setFitView();
    },
  },
};
</script>

<style lang='scss' scoped>
.change-frame {
  background-image: url("../../assets/chance_bg.png");
  background-size: 100% 100%;
}
#wrap {
  width: 100%;
  height: 50vh;
}
::v-deep .mapDialog .el-dialog {
  background-color: #fff;
}
.content {
  width: 100%;
  min-width: 1100px;
  background-color: rgb(250 251 253 / 70%);
  position: relative;
  box-shadow: 0 2px 5px rgba($color: #aaa, $alpha: 0.3);
  padding: 34px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  .left {
    width: 350px;

    .search_box {
      display: flex;
      justify-content: space-between;

      .ipt {
        width: 250px;
        border-radius: 23px;
      }
    }
    .type_box {
      height: calc(100% - 52px);
      background-color: #fff;
      margin-top: 12px;
      overflow: hidden;

      .type_item {
        padding: 10px;
        cursor: pointer;
        color: #cfcfcf;
        border-bottom: 1px solid #efefef;

        &:hover {
          color: $theme;

          .label {
            color: $theme;

            &::before {
              background-color: $theme;
            }
          }
        }
        .show {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .label {
          padding-left: 20px;
          color: #333;
          font-size: 18px;
          position: relative;
          &::before {
            content: "";
            width: 3px;
            height: 70%;
            border-radius: 10px;
            background-color: #cfcfcf;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        .sub_list {
          width: 100%;
          box-sizing: border-box;
          margin-top: 7px;
          background-color: #f0f5fa;
          color: #666;
          max-height: 0;
          transition: maxHeight 0.5s;
          overflow: hidden;
          font-size: 14px;

          .sub_item {
            height: 40px;
            line-height: 40px;
            padding: 0 15px;
            cursor: pointer;

            &:hover {
              color: #fff;
              background-color: $theme;
            }
            &:last-child .sub_title {
              border-bottom: 0;
            }
            .sub_title {
              display: flex;
              justify-content: space-between;
              align-items: center;
              border-bottom: 1px solid #cfcfcf;

              i {
                font-size: 14px;
              }
            }
          }
          .sub_active {
            color: #fff;
            background-color: $theme;
          }
        }
      }
      .active {
        color: $theme;

        .label {
          font-weight: bold;
          color: $theme;

          &::before {
            background-color: $theme;
          }
        }

        .sub_list {
          max-height: 1000px;
        }
      }
      .eaches {
        width: 230px;
        overflow: auto;

        .each {
          padding: 10px 10px 10px 30px;
          position: relative;

          &:hover {
            color: #fff;
            cursor: pointer;
            background-color: $theme;
          }

          i {
            color: #fff;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        .each_active {
          color: #fff;
          background-color: $theme;
        }
      }
    }
  }
  .right {
    flex-grow: 1;
    margin-left: 18px;
    padding: 20px 30px 10px;
    box-sizing: border-box;
    background-color: #fff;

    .title {
      position: relative;
      padding-left: 30px;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 20px;

      i {
        color: $theme;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .look {
      cursor: pointer;
      font-size: 13px;
      text-decoration: underline;
      &:hover {
        color: $theme;
      }
    }

    .desc {
      width: 400px;
      margin-left: 20px;
      background-color: #fff;
      padding: 10px 15px;
      box-sizing: border-box;
      flex-shrink: 0;

      .choose {
        height: 40px;
        border: 1px solid #eee;
        border-radius: 5px;
        display: flex;
        justify-content: space-evenly;
        font-size: 18px;
        line-height: 40px;

        .choice {
          height: 100%;
          cursor: pointer;
          position: relative;

          &:hover {
            color: $theme;

            &::after {
              width: 100%;
            }
          }
          &::after {
            content: "";
            display: block;
            width: 0;
            height: 2px;
            border-radius: 5px;
            background-color: $theme;
            position: absolute;
            left: 0;
            bottom: -1px;
            transition: width 0.2s;
          }
        }
        .active {
          font-weight: bold;
          color: $theme;

          &::after {
            width: 100%;
          }
        }
      }
      .bottom {
        font-size: 14px;
        line-height: 1.5;
        margin-top: 25px;
        padding: 0 5px;
        text-align: justify;
        max-height: calc(100% - 70px);
        overflow: auto;
      }
    }
  }
  .map {
    padding: 0;
    display: flex;
    background-color: transparent;

    #container {
      width: 100%;
      height: 100%;
    }
  }
}
@media (max-width: 1500px) {
  .content .left {
    width: 300px;

    .search_box .ipt {
      width: 200px;
    }
    .type_box {
      .type .type_item .label {
        font-size: 16px;
      }
      .eaches {
        font-size: 14px;
      }
    }
  }
  .content .right .desc {
    width: 300px;
  }
}
</style>